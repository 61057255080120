export const debug = window.location.hostname.startsWith('localhost');
export const URL = {
    API: 'https://brain.dev.kustod.io',
    CODOOZER: 'https://dev.codoozer.com',
    DO_CDN: 'https://dixibox-dev.ams3.cdn.digitaloceanspaces.com',
    DOYO: 'https://dev.doyo.tech',
    FACE: 'https://dev.kustod.io',
    GRAVATAR: 'https://secure.gravatar.com/avatar/',
    PASSPORT: 'https://passport.dev.doyo.tech/',
};

export const SECRETS = {
    ANALYTICS: 'UA-147524391-1',
    sentryDsn: 'https://1467bcaf6d4e4f9db3267fdff9a66c23@sentry.io/1736637',
    stripeApiKey: 'pk_test_6KDdHJBIRmolU8shC5N6Bv3G',
};
